.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1em;
  line-height: 1.5em;
  vertical-align: middle;

  .text {
    display: inline;
    flex: initial;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    margin-right: 4px;
  }
}
