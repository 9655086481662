.nyu {
  margin-top: 50%;
  margin-left: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
}

.visible {
  @media (prefers-reduced-motion: no-preference) {
    animation: 1s cubic-bezier(0.75, 0.1, 0.25, 0.9) 1 both nyu;
  }

  @media (prefers-reduced-motion: reduce) {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}

@keyframes nyu {
  from {
    opacity: 0;
    transform: translateX(-40%) translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}
