$logo_size: 256px;

.container {
  position: relative;
}

.portrait {
  width: $logo_size;
  height: $logo_size;
  pointer-events: none;

  > img {
    width: 150%;
    height: 150%;
  }
}

.fave {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: fit-content;
  height: fit-content;
  margin-bottom: 4px;

  .tooltip {
    // https://zenn.dev/catnose99/articles/26bd8dac9ea5268486c8
    position: relative;
    text-decoration: none;
    cursor: pointer;
  }

  .tooltipText {
    position: absolute;
    bottom: -35px;
    left: 50%;
    display: inline-block;
    padding: 5px;
    font-size: 0.8rem;
    line-height: 1.3;
    color: #fff;
    white-space: nowrap;
    visibility: hidden;
    background: #333;
    border-radius: 3px;
    opacity: 0;
    transition: 0.3s ease-in;
    transform: translateX(-50%);

    &::before {
      position: absolute;
      top: -13px;
      left: 50%;
      margin-left: -7px;
      content: '';
      border: 7px solid transparent;
      border-bottom: 7px solid #333;
    }
  }

  .tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}
