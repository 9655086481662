@import '../../variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  margin: 16px 0;

  .nameContainer {
    width: 100%;
    margin: 16px 0;

    .name {
      $font-size: 2em;

      margin: 8px 0;
      font-size: $font-size;
      font-weight: bold;
      line-height: $font-size;
    }

    .description {
      $font-size: 1em;

      margin: 8px 0;
      font-size: $font-size;
      line-height: $font-size;
      color: $foreground-alt;
    }
  }

  .social {
    width: 100%;
    line-height: 1.5em;

    svg {
      color: $foreground-alt;
    }
  }
}

.article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  margin: 16px 0;

  .accordion {
    width: 100%;
    margin: 16px 0;
  }

  .list {
    $font-size: 1em;

    font-size: $font-size;
    line-height: $font-size * 1.5;
    text-align: left;
    list-style: none;

    ul {
      list-style: none;
    }

    & > li {
      font-weight: bold;

      > ul {
        font-weight: initial;
      }
    }
  }
}
